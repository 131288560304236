var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Users ")]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"placeholder":"Username"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.CariUser}},[_vm._v("Cari")])]},proxy:true}]),model:{value:(_vm.cariUsername),callback:function ($$v) {_vm.cariUsername=$$v},expression:"cariUsername"}})],1)],1),_c('CDataTable',{attrs:{"hover":"","striped":"","items":_vm.items,"fields":_vm.fields,"items-per-page":10,"active-page":_vm.activePage,"pagination":{ doubleArrows: false, align: 'center'}},on:{"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"status",fn:function(data){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(data.item.status)}},[_vm._v(" "+_vm._s(_vm.nameStatus(data.item.status))+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"secondary","square":"","size":"sm"},on:{"click":function($event){return _vm.updateStatus(item)}}},[_vm._v(" Verifikasi ")])],1)]}}])})],1)],1)],1),_c('CModal',{attrs:{"title":"Change Status","color":"warning","show":_vm.changeStatusModal},on:{"update:show":function($event){_vm.changeStatusModal=$event}}},[_c('CForm',{on:{"submit":_vm.submitChangeStatus}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"label":"Username","disabled":""},model:{value:(_vm.fieldupdateStatus.username),callback:function ($$v) {_vm.$set(_vm.fieldupdateStatus, "username", $$v)},expression:"fieldupdateStatus.username"}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"label":"Status","value":_vm.fieldupdateStatus.status,"options":[
          'Pilih Status', 
          { value:'2', label: 'Verifikasi'}, 
          { value:'1', label: 'Pending'},
          { value:'0', label: 'Delete'}
        ]},on:{"update:value":function($event){return _vm.$set(_vm.fieldupdateStatus, "status", $event)}}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CButton',{attrs:{"color":"warning","square":"","size":"sm"},on:{"click":_vm.modalKembaliStatus}},[_vm._v("Cancel")])],1),_c('CCol',{attrs:{"sm":"6","align":"right"}},[_c('CButton',{attrs:{"color":"success","type":"submit","square":"","size":"sm"}},[_vm._v("Submit")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }