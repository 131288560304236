<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          Users
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CInput 
              placeholder="Username"
              v-model="cariUsername">
              <template #append>
                <CButton color="primary" @click="CariUser">Cari</CButton>
              </template>
              </CInput>
            </CCol>
          </CRow>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
            <template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{nameStatus(data.item.status)}}
                </CBadge>
              </td>
            </template>
            <template #show_details="{item}">
            <td class="py-2">
              <CButton
                color="secondary"
                square
                size="sm"
                @click="updateStatus(item)"
              >
                Verifikasi
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      title="Change Status"
      color="warning"
      :show.sync="changeStatusModal"
    >
      <CForm @submit="submitChangeStatus">
      <CRow>
        <CCol sm="12">
          <CInput
            label="Username"
            v-model="fieldupdateStatus.username"
            disabled
          />
        </CCol>
        <CCol sm="12">
          <CSelect
          label="Status"
          :value.sync="fieldupdateStatus.status"
          :options="[
            'Pilih Status', 
            { value:'2', label: 'Verifikasi'}, 
            { value:'1', label: 'Pending'},
            { value:'0', label: 'Delete'}
          ]"
          />
        </CCol>
        <CCol sm="6">
          <CButton color="warning" square size="sm" @click="modalKembaliStatus">Cancel</CButton>
        </CCol>
        <CCol sm="6" align="right">
          <CButton color="success" type="submit" square size="sm">Submit</CButton>
        </CCol>
      </CRow>
      </CForm>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Withdraw',
  data () {
    return {
      urlApi:this.$store.state.urlApi,
      changePasswordModal:false,
      changeStatusModal:false,
      fieldupdateStatus:{
        id:null,
        username:null,
        status:null
      },
      idUser:null,
      items: [],
      fields: [
        { key: 'username', label: 'Username', _classes: 'font-weight-bold' },
        { key: 'withdraw_cloudcash_balance_wallet', label: 'Withdraw Cloud Cash', },
        { key: 'withdraw_bitcoin_balance_wallet', label: 'Withdraw Bitcoin' },
        { key: 'btc_address', label: 'Bitcoin Address' },
        { key: 'date_withdraw', label: 'Tanggal' },
        { key: 'status', label: 'Status'  },
        { 
          key: 'show_details', 
          label: 'Action', 
          _style: 'width:1%', 
          sorter: false, 
          filter: false,
          align: 'center'
        }
      ],
      activePage: 1,
      cariUsername:null
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/log-withdraw`).then(response => {
      this.items = response.data
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case '0': return 'warning'
        case '1': return 'secondary'
        case '2': return 'success'
        default: 'warning'
      }
    },
    nameStatus (status) {
      switch (status) {
        case '0': return 'Delete'
        case '1': return 'Pending'
        case '2': return 'Verified'
        default: 'Pending'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    CariUser(){
      axios.get(`${this.urlApi}/api/log-withdraw/get/${this.cariUsername}`).then(response => {
        this.items = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    updateStatus(item){
      this.changeStatusModal = true
      this.fieldupdateStatus.id = item.id
      this.fieldupdateStatus.username = item.username
      this.fieldupdateStatus.status = item.status

      this.idUser = item.id_user
    },
     modalKembaliStatus(){
      this.fieldupdateStatus.id = ''
      this.fieldupdateStatus.username = ''
      
      this.changeStatusModal = false
      
    },
    submitChangeStatus(evt){
      evt.preventDefault()


      axios.get(`${this.urlApi}/api/user/getUserByUsername/${this.fieldupdateStatus.username}`).then(responseUsers => {

        axios.get(`${this.urlApi}/api/log-withdraw/get/${this.fieldupdateStatus.username}`).then(responseLog => {


          let updateData = {
            cloudcash_balance_wallet: responseUsers.data.cloudcash_balance_wallet - responseLog.data[0].withdraw_cloudcash_balance_wallet,
            bitcoin_balance_wallet: parseFloat(responseUsers.data.bitcoin_balance_wallet-responseLog.data[0].withdraw_bitcoin_balance_wallet).toFixed(8)
          }

           let updateStatus = {
             status:this.fieldupdateStatus.status
           }


          axios.put(`${this.urlApi}/api/log-withdraw/${this.fieldupdateStatus.id}`,updateStatus).then(responseChangeStatus => {
            
            if(this.fieldupdateStatus.status == 2){
              axios.put(`${this.urlApi}/api/user-details/${this.idUser}`,updateData).then(responseUpdateUser => {
               location.reload()
              })
            } else {
              location.reload()
            }

          })

        
        })

      })

    }
  }
}
</script>
